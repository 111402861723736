import i18n from "i18next";

import moment from "moment";

export const langOptions = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "es-AR",
    name: "Castellano",
  },
];

const enUs = require("./locales/en-US.json");
const esAR = require("./locales/es-AR.json");

const resources = {
  "en-US": {
    translation: enUs,
  },
  "es-AR": {
    translation: esAR,
  },
};

i18n.init({
  resources,
  fallbackLng: "en-US",
  interpolation: {
    escapeValue: false,
  },
});

i18n.on("languageChanged", function (lang) {
  moment.locale(lang);
});

// gus ignore
export function _t(k: string, args: { [id: string]: any } = {}): string {
  return i18n.t(k, args) as string;
}

const exportedSymbol = { translate: _t, _t };
export default exportedSymbol;
